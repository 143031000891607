<template>
  <div>
    <base-page 
    title="Suppliers"
    :breadcrumbs="breadcrumbs"
    call-to-action-text="Add Supplier"
    call-to-action-permission="nchi.jurisdictions.store"
    @call-to-action="dialog = true">
      <template #content>
        <supplier-list/>
      </template>
    </base-page>
    <supplier-form
      v-if="dialog"
      :dialog="dialog"
      :supplier="supplier"
      @close="close()"
    ></supplier-form>
  </div>
</template>

<script>
export default {
  components: {
    'supplier-list': () => import('@/views/supplier/SupplierList.vue'),
    'supplier-form': () => import('@/views/supplier/SupplierForm.vue'),
  },
  data() {
    return {
      dialog: false,
      supplier: null,
      breadcrumbs: [{ text: "Suppliers", disabled: true }],
    };
  },
  methods: {
    close () {
      this.dialog = false
    }
  }
};
</script>
